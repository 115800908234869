<template>
  <div class="Scheme">
    <titles></titles>
    <div class="banner">
      <div class="contact_banner_txt">
        <div class="contact_banner_n_box">
          <div class="contact_bg_txt_box">
            <h2>BANGMANG</h2>
            <div class="contact_sm_txt">
              <h3>精选行业案例</h3>
              <p>携手赋能 共创未来</p>
            </div>

            <div class="banner_btn">咨询方案</div>
          </div>
        </div>
      </div>
      <img src="@/assets/scheme_banner.jpg" />
    </div>
    <IndustrySolutions :type="2" :swiperSlideIndex="type" ref="IndustrySolutions"></IndustrySolutions>

    <foots :type="3" @navChange = "navChange"></foots>
  </div>
</template>
<script>
import IndustrySolutions from "@/components/IndustrySolutions.vue";

import titles from "@/components/titles.vue";
import foots from "@/components/foots.vue";

export default {
  name: "Scheme",
  components: {
    titles,
    foots,
    IndustrySolutions,
  },
  data() {
    return {
      type: ''
    };
  },
  mounted() {
      this.type = this.$route.query.type
      window.scrollTo(0, 0);
  },
  methods:{
    navChange(e){
      this.$refs.IndustrySolutions.slideType = e
      this.$refs.IndustrySolutions.handler()
      window.scrollTo(0, 0);
    }
  }

};
</script>


<style lang="scss" scope>
.banner_btn {
  margin: 0 auto;
  font-size: 16px;
  width: 140px;
  height: 40px;
  border-radius: 15px;
  text-align: center;
  color: #fff;
  background: #d70035;
  line-height: 40px;
  margin-top: 115px;
  // margin-top: 145px;
}

.contact_banner_txt {
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -200px;

  .contact_banner_n_box {
    width: 80%;
    margin: 0 auto;
  }

  .contact_bg_txt_box {
    display: table;
    position: relative;
  }

  h2 {
    font-size: 111px;
    color: #ffd370;
    opacity: 0.1;
  }

  .contact_sm_txt {
    position: absolute;
    width: 100%;
    top: 115px;
    left: 0;
    color: #fff;

    h3 {
      font-size: 55px;
      margin: 0;
      margin-bottom: 50px;
    }

    p {
      font-size: 24px;
    }
  }
}

.banner {
  position: relative;
}</style>